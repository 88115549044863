import React, { useEffect, useState } from "react";
import { Modal, Button, message, Input, Form, Select, Row, Col } from "antd";
import { LocalStorageConstants } from "../../constants/localStorageConstants";
import * as masterServices from "../../services/masterServices";
import * as treeService from "../../services/treeServices";
import { OrgMasterModulesAndMapping, ModuleAndIdMapping } from "../../constants/databaseConstants";
import * as orgmasterServices from "../../services/orgmasterServices";

interface TreeDetailsUpdateModelProps {
  timelineData: any;
  onCancel: () => void;
  onSave: (editedData: any) => void;
  editMode: string;
}

const TreeDetailsUpdateModel: React.FC<TreeDetailsUpdateModelProps> = ({ timelineData, onCancel, onSave, editMode }) => {
  const [form] = Form.useForm();
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const [loader, setLoader] = useState<boolean>(false);
  const [healthConditionlist, setHealthConditionlist] = useState<number[]>([]);
  const [treeBotanicalNamelist, setTreeBotanicalNamelist] = useState<any[]>([]);
  const [caretakerlist, setCaretakerlist] = useState<number[]>([]);
  const [farmerlist, setFarmerlist] = useState<number[]>([]);
  const [isActive, setIsactive] = useState<string>("");
  const [selectedBotanicalId, setSelectedBotanicalId] = useState(null);
  const [selectedCaretakerId, setSelectedCaretakerId] = useState(null);
  const [selectedFarmerId, setSelectedFarmerId] = useState(null);
  const [selectedHealthId, setSelectedHealthId] = useState(null);
  const [selectedHeightInCm, setSelectedHeightInCm] = useState<number | null>(null);
  const [selectedGirthInCm, setSelectedGirthInCm] = useState<number | null>(null);

  console.log(">>>>>>>>>>>timelineData,", timelineData);

  const labelColStyle = {
    textAlign: "right" as const,
    width: "80px",
    marginRight: "10px"
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      style: labelColStyle
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    }
  };

  const calculateHeightInFt = (selectedHeightInCm: any) => {
    if (selectedHeightInCm === null || isNaN(selectedHeightInCm)) {
      return null;
    }
    const formattedValue = parseFloat(selectedHeightInCm);
    const result = (formattedValue / 30.48).toFixed(2);
    return result;
  };

  const calculateGirthInMeter = (selectedGirthInCm: any) => {
    if (selectedGirthInCm === null || isNaN(selectedGirthInCm)) {
      return null;
    }
    const formattedValue = parseFloat(selectedGirthInCm.toFixed(2));
    const result = (formattedValue / 100).toFixed(2);
    return result;
  };

  const renderFormFields = () => {
    if (editMode === "header") {
      return (
        <>
          <Form.Item label="Tree Tag" name="TreeTag">
            <Input className="gx-text-gray gx-mb-1" readOnly disabled />
          </Form.Item>
          <Form.Item label="Tree General Name" name="TreeBotanicalId">
            <Select
              placeholder="Select Tree General Name"
              onChange={handleTreeGeneralNameChange}
              defaultValue={timelineData?.TreeBotanicalName?.TreeGeneralName}
              showSearch
              filterOption={(input: any, option: any) => {
                const children = option?.children as unknown;
                if (typeof children === "string") {
                  return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
            >
              {treeBotanicalNamelist.map((item: any) => (
                <Select.Option key={item.id} value={item.TreeBotanicalId}>
                  {item.TreeGeneralName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Botanical Name" name="BotanicalName">
            <Input className="gx-text-gray gx-mb-1" readOnly disabled />
          </Form.Item>

          <Form.Item label="Caretaker" name="CaretakerId">
            <Select
              className="gx-text-gray gx-mb-1"
              placeholder="Select Caretaker"
              onChange={(value) => setSelectedCaretakerId(value)}
              defaultValue={timelineData?.Caretaker?.CaretakerName}
              showSearch
              filterOption={(input: any, option: any) => {
                const children = option?.children as unknown;
                if (typeof children === "string") {
                  return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
            >
              {caretakerlist.map((item: any) => (
                <Select.Option key={item.id} value={item.CareTakerId}>
                  {item.CareTakerName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Farmer" name="FarmerId">
            <Select
              className="gx-text-gray gx-mb-1"
              placeholder="Select Farmer"
              onChange={(value) => setSelectedFarmerId(value)}
              defaultValue={timelineData?.Farmer?.FarmerName}
              showSearch
              filterOption={(input: any, option: any) => {
                const children = option?.children as unknown;
                if (typeof children === "string") {
                  return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
            >
              {farmerlist.map((item: any) => (
                <Select.Option key={item.id} value={item.FarmerId}>
                  {item.FarmerName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
      );
    } else if (editMode === "transaction") {
      return (
        <>
          <Form.Item label="Tree Tag" name="TreeTag">
            <Input className="gx-text-gray gx-mb-1" readOnly disabled />
          </Form.Item>

          <Form.Item label="Tree Health Condition" name="TreeHealthId">
            <Select
              placeholder="Select Tree Health Condition"
              onChange={(value) => setSelectedHealthId(value)}
              defaultValue={timelineData?.treeTransaction?.[0]?.TreeHealthCondition?.TreeHealth}
              showSearch
              filterOption={(input: any, option: any) => {
                const children = option?.children as unknown;
                if (typeof children === "string") {
                  return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              }}
            >
              {healthConditionlist.map((item: any) => (
                <Select.Option key={item.id} value={item.TreeHealthId}>
                  {item.TreeHealth}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Height"
            name="Height"
            rules={[
              {
                required: true,
                message: "Please enter height in cm",
                type: "number",
                transform: (value) => Number(value)
              }
            ]}
          >
            <Input.Group compact>
              <Form.Item
                name="HeightInCm"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please enter height in cm",
                    type: "number",
                    transform: (value) => Number(value)
                  },
                  {
                    required: true,
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.reject("Please fill the field");
                      }
                      const numericValue = Number(value);
                      if (isNaN(numericValue) || numericValue > 10000) {
                        return Promise.reject("Please enter number up to 10000 cm");
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = parseFloat(value);
                    setSelectedHeightInCm(numericValue);
                    form.setFieldsValue({ HeightInFt: calculateHeightInFt(parseFloat(value)) });
                  }}
                  style={{ width: "37.5%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                />
              </Form.Item>
              <span style={{ marginLeft: 8, alignSelf: "center", marginTop: "6px" }}>(cm)</span>
              <Form.Item name="HeightInFt" noStyle>
                <Input style={{ width: "37.5%", marginLeft: "15px", borderRadius: "5px" }} readOnly disabled />
              </Form.Item>
              <span style={{ marginLeft: 8, marginTop: "6px" }}>(ft)</span>
            </Input.Group>
          </Form.Item>

          <Form.Item
            label="Girth"
            name="Girth"
            rules={[
              {
                required: true,
                message: "Please enter girth in cm",
                type: "number",
                transform: (value) => Number(value)
              }
            ]}
          >
            <Input.Group compact>
              <Form.Item
                name="GirthInCm"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please enter girth in cm",
                    type: "number",
                    transform: (value) => Number(value)
                  },
                  {
                    required: true,
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.reject("Please fill the field");
                      }
                      const numericValue = Number(value);
                      if (isNaN(numericValue) || numericValue > 1000) {
                        return Promise.reject("Please enter number up to 1000 cm");
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
              >
                <Input
                  onChange={(e) => {
                    const value = e.target.value;
                    const numericValue = parseFloat(value);
                    setSelectedGirthInCm(numericValue);
                    form.setFieldsValue({ GirthInMeter: calculateGirthInMeter(parseFloat(value)) });
                  }}
                  style={{ width: "37.5%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px" }}
                />
              </Form.Item>
              <span style={{ marginLeft: 8, alignSelf: "center", marginTop: "6px" }}>(cm)</span>
              <Form.Item name="GirthInMeter" noStyle>
                <Input style={{ width: "37.5%", marginLeft: "15px", borderRadius: "5px" }} readOnly disabled />
              </Form.Item>
              <span style={{ marginLeft: 8, marginTop: "6px" }}>(m)</span>
            </Input.Group>
          </Form.Item>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    form.setFieldsValue({
      TreeTag: timelineData?.TreeTag,
      TreeGeneralName: timelineData?.TreeBotanicalName?.TreeGeneralName,
      BotanicalName: timelineData?.TreeBotanicalName?.BotanicalName,
      CaretakerName: timelineData?.Caretaker?.CaretakerName,
      FarmerName: timelineData?.Farmer?.FarmerName,
      TreeHealthCondition: timelineData?.treeTransaction?.[0]?.TreeHealthCondition?.TreeHealth,
      GirthInCm: timelineData?.treeTransaction?.[0]?.GirthInCm,
      HeightInCm: timelineData?.treeTransaction?.[0]?.HeightInCm,
      HeightInFt: timelineData?.treeTransaction?.[0]?.HeightInFt,
      GirthInMeter: timelineData?.treeTransaction?.[0]?.GirthInMeter
    });
  }, [timelineData]);

  useEffect(() => {
    const fetchHealthConditionlist = async () => {
      try {
        setHealthConditionlist([]);
        const response = await masterServices.getOneMasterListById(TOKEN!, ModuleAndIdMapping.TREEHEALTH);

        const res =
          response &&
          response.AllRecordsList.map((item: any) => {
            return {
              ...item,
              key: item.TreeHealthId,
              name: item.TreeHealth,
              id: item.TreeHealthId
            };
          });
        setHealthConditionlist(res);
      } catch (error: any) {
        setHealthConditionlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchHealthConditionlist();
  }, []);

  //api call to fetch tree botanical name //
  useEffect(() => {
    const fetchBotanicalNamelist = async () => {
      try {
        setTreeBotanicalNamelist([]);
        const response = await masterServices.getOneMasterListById(TOKEN!, ModuleAndIdMapping.TREEBOTANICAL);

        const res =
          response &&
          response.AllRecordsList.map((item: any) => {
            return {
              ...item,
              key: item.TreeBotanicalId,
              TreeGeneralName: item.TreeGeneralName,
              BotanicalName: item.BotanicalName,
              Devnagari: item.Devnagari,
              id: item.TreeBotanicalId
            };
          });
        setTreeBotanicalNamelist(res);
      } catch (error: any) {
        setTreeBotanicalNamelist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchBotanicalNamelist();
  }, []);

  /* caretake list */
  useEffect(() => {
    const fetchCareTakerlist = async () => {
      try {
        setCaretakerlist([]);
        const response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, OrgMasterModulesAndMapping.CARETAKER, isActive);
        const res = response.AllRecordsList.map((item: any) => {
          return {
            ...item,
            key: item.CareTakerId,
            name: item.CareTakerName,
            id: item.CareTakerId
          };
        });
        if (res.length > 0) {
          setCaretakerlist(res);
        } else {
          setCaretakerlist([]);
        }
      } catch (error: any) {
        setCaretakerlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchCareTakerlist();
  }, []);

  /* farmerlist list */
  useEffect(() => {
    const fetchFarmerlist = async () => {
      try {
        setFarmerlist([]);
        const response = await orgmasterServices.getFarmerList(TOKEN!, timelineData?.Area?.Village?.VillageId, isActive);
        const res = response.record.map((item: any) => {
          return {
            ...item,
            key: item.FarmerId,
            name: item.FarmerName,
            id: item.FarmerId
          };
        });
        if (res.length > 0) {
          setFarmerlist(res);
        } else {
          setFarmerlist([]);
        }
      } catch (error: any) {
        setFarmerlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchFarmerlist();
  }, []);

  // Handle change in Tree General Name dropdown
  const handleTreeGeneralNameChange = (value: any) => {
    const selectedTree = treeBotanicalNamelist.find((item: any) => item.TreeBotanicalId === value);
    if (selectedTree) {
      setSelectedBotanicalId(selectedTree.TreeBotanicalId);
      form.setFieldsValue({
        BotanicalName: selectedTree.BotanicalName
      });
    }
  };

  console.log(">>>>>>>>>>>village id", farmerlist);
  const handleSave = async () => {
    try {
      setLoader(true);
      const isFormValid = form.isFieldsTouched() && !form.getFieldsError().some((field) => field.errors.length > 0);
      if (isFormValid) {
        const values = form.getFieldsValue();
        let response;
        if (editMode === "header") {
          const updateHeaderData = {
            TreeBotanicalId: selectedBotanicalId ?? timelineData?.TreeBotanicalName?.TreeBotanicalId,
            CaretakerId: selectedCaretakerId ?? timelineData?.Caretaker?.CaretakerId,
            FarmerId: selectedFarmerId ?? timelineData?.Farmer?.FarmerId
          };

          response = await treeService.updateTreeHeader(TOKEN!, timelineData.TreeId, updateHeaderData);
        } else if (editMode === "transaction") {
          const updatetransactionData = {
            TreeHealthId: selectedHealthId ?? timelineData?.treeTransaction?.[0]?.TreeHealthCondition?.TreeHealthId,
            HeightInCm: Number(selectedHeightInCm ?? timelineData?.treeTransaction?.[0]?.HeightInCm),
            HeightInFt: Number(selectedHeightInCm ? calculateHeightInFt(selectedHeightInCm) : timelineData?.treeTransaction?.[0]?.HeightInFt),
            GirthInCm: Number(selectedGirthInCm ?? timelineData?.treeTransaction?.[0]?.GirthInCm),
            GirthInMeter: Number(selectedGirthInCm ? calculateGirthInMeter(selectedGirthInCm) : timelineData?.treeTransaction?.[0]?.GirthInMeter)
          };

          response = await treeService.updateTreeTransaction(TOKEN!, timelineData.TreeId, updatetransactionData);
        }

        if (response) {
          message.destroy();
          message.success(response.message);
          onSave(response);
          form.resetFields();
        }
      }
    } catch (error: any) {
      message.destroy();
      message.error("Error: " + (error?.message || ""));
    } finally {
      setLoader(false);
    }
  };
  return (
    <Modal
      title={editMode === "header" ? "Tree Header " : "Tree Transaction "}
      visible={true}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" type="primary" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} disabled={loader}>
          Update
        </Button>
      ]}
      width={550}
    >
      <Form form={form} {...formItemLayout} labelAlign="left">
        {/* Render form fields based on the mode */}
        {renderFormFields()}
      </Form>
    </Modal>
  );
};

export default TreeDetailsUpdateModel;

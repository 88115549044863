import { Table, Space, Card, message, Select, Button, Spin, DatePicker, Tooltip, Checkbox } from "antd";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { useSelector } from "react-redux";
import * as reportService from "../../../services/reportService";
import * as orgmasterServices from "../../../services/orgmasterServices";
import styles from "../reports.module.css";
import { TablePaginationConfig } from "antd/lib/table/interface";
import { RootState } from "../../../appRedux/store";
import Dropdown from "../filterComponent";
import CommonModal from "../../Modal";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { OrgMasterModulesAndMapping } from "../../../constants/databaseConstants";
import { Column } from "../../../shared/interfaces/Report.interface";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { LocalStorageConstants } from "../../../constants/localStorageConstants";
import { Link } from "react-router-dom";

const ReplantationReport = () => {
  // CONSTANTS
  const TOKEN = localStorage.getItem(LocalStorageConstants.TOKEN);
  const filtersDataFromLocalStorage = localStorage.getItem(LocalStorageConstants.FILTERS_DATA);
  const { navCollapsed } = useSelector(({ common }: RootState) => common);

  const [fromDate, setFromDate] = useState<Moment | null>(null);
  const [toDate, setToDate] = useState<Moment | null>(null);
  const pageSizeOptions = ["10", "20", "50", "100"];
  const [pagination, setPagination] = useState<TablePaginationConfig>({ current: 1, pageSize: 10 });
  const [reportsData, setReportsData] = useState<any>();

  const [projectlist, setProjectlist] = useState<number[]>([]);
  const [clusterlist, setClusterlist] = useState<number[]>([]);
  const [villagelist, setVillagelist] = useState<number[]>([]);
  const [farmerlist, setFarmerlist] = useState<number[]>([]);
  const [arealist, setArealist] = useState<number[]>([]);
  const [healthConditionlist, setHealthConditionlist] = useState<number[]>([]);

  const [projectId, setProjectId] = useState<number | null>(null);
  const [clusterId, setClusterId] = useState<number | null>(null);
  const [villageId, setVillageId] = useState<number | null>(null);
  const [farmerId, setFarmerId] = useState<number | null>(null);
  const [areaId, setAreaId] = useState<number[]>([]);
  const [healthConditionId, setHealthConditionId] = useState<number[] | []>([]);

  /* export table data in excel sheet*/
  const [reportExportData, setReportExportData] = useState<any | null>();

  const [selectDate, setSelectDate] = useState<number | null>(90);

  const [filterSelectOption, setFilterSelectOption] = useState<string | null>("1");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [handleSubmit, setHandleSubmit] = useState(false);

  const [qrCodePaths, setQRCodePaths] = useState([]);

  const [isActive, setIsactive] = useState<boolean>(false);
  const [isActiveTransactionHistory, setIsactiveTransactionHistory] = useState<boolean>(false);

  /* loading state */
  const [formLoader, setFormLoader] = useState<boolean>(false);

  /* formDate and toDate converted to YYYY-MM-DDTHH:mm:ss.SSS[Z] this format  */
  const formattedFromDate = fromDate ? fromDate.clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";
  const formattedToDate = toDate ? toDate.clone().seconds(0).milliseconds(0).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : "";

  const dateTimeValidation = (fromDateIsValid: boolean, toDateIsValid: boolean, formattedFromDate: string, formattedToDate: string) => {
    /* Validations */
    if (!fromDateIsValid) {
      message.error("Invalid 'From' date format.");
      return false;
    }

    if (!toDateIsValid) {
      message.error("Invalid 'To' date format.");
      return false;
    }

    if (formattedFromDate === formattedToDate) {
      message.error("The selected times should be different.");
      return false;
    }

    if (formattedFromDate > formattedToDate) {
      message.error("Invalid date range: 'From' date should be smaller than 'To' date.");
      return false;
    }
    return true;
  };

  const getDefaulthealthConditionIds = (healthConditionlist: any) => {
    if (!healthConditionlist || !Array.isArray(healthConditionlist)) {
      return [];
    }

    return healthConditionlist.map((item) => item.id);
  };

  useEffect(() => {
    // Load filters data from LocalStorage on component mount
    if (filtersDataFromLocalStorage) {
      const filters = JSON.parse(filtersDataFromLocalStorage);
      // Now you can use the filters object to set your state values
      setFromDate(moment(filters?.fromDate));
      setToDate(moment(filters?.toDate));
      setSelectDate(filters?.selectDate);
      setFilterSelectOption(filters?.filterSelectOption);
      setProjectId(filters?.projectId);
      setClusterId(filters?.clusterId);
      setVillageId(filters?.villageId);
      setAreaId(filters?.areaId);
      setIsactive(filters?.isActive);
      setIsactiveTransactionHistory(filters?.isActiveTransactionHistory);
      setHandleSubmit(true);
    }
  }, []);

  useEffect(() => {
    handleChangeHealthCondition(getDefaulthealthConditionIds(healthConditionlist));
  }, [healthConditionlist]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setHandleSubmit(false);
        // Validating date and time formats.
        const fromDateIsValid = moment(formattedFromDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", true).isValid();
        const toDateIsValid = moment(formattedToDate, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", true).isValid();

        if (!dateTimeValidation(fromDateIsValid, toDateIsValid, formattedFromDate, formattedToDate)) {
          return;
        }

        if (!projectId || projectId === null) {
          message.error("Please select appropriate filter. At least project selection is mandatory");
          return;
        }

        setReportsData([]);
        setFormLoader(true);

        const Filters = {
          fromDate,
          toDate,
          selectDate,
          filterSelectOption,
          projectId,
          clusterId,
          villageId,
          areaId,
          isActive,
          isActiveTransactionHistory
        };

        localStorage.setItem(LocalStorageConstants.FILTERS_DATA, JSON.stringify(Filters));

        /* check Report is Replantation or not*/
        const isReplantationData: boolean = true;
        const isTagMissingData: boolean = false;

        const response = await reportService.ReportTableData(
          TOKEN!,
          projectId === null ? [] : Array.isArray(projectId) ? projectId : [projectId],
          clusterId === null ? [] : Array.isArray(clusterId) ? clusterId : [clusterId],
          villageId === null ? [] : Array.isArray(villageId) ? villageId : [villageId],
          farmerId === null ? [] : Array.isArray(farmerId) ? farmerId : [farmerId],
          areaId,
          healthConditionId,
          formattedFromDate,
          formattedToDate,
          String(isActiveTransactionHistory),
          isReplantationData,
          isTagMissingData
        );

        const processedData =
          response &&
          response.treeReportData.map((item: any, index: number) => {
            return {
              key: index,
              ...item,
              TreeGeneralName: item.TreeBotanicalName.TreeGeneralName,
              BotanicalName: item.TreeBotanicalName.BotanicalName,
              TrackingDate: item.TreeTransaction[0].TrackingDate,
              HeightInCm: item.TreeTransaction[0].HeightInCm,
              GirthInCm: item.TreeTransaction[0]?.GirthInCm ?? 0,
              TreeHealth: item.TreeTransaction[0].TreeHealthCondition.TreeHealth,
              ProjectName: item.Area.Project.ProjectName,
              ClusterName: item.Area.Cluster.ClusterName,
              VillageName: item.Area.Village.VillageName,
              AreaName: item.Area.AreaName,
              FarmerName: item.Farmer.FarmerName
            };
          });
        setReportsData(processedData);

        const extractedPaths = response && response.treeReportData.map((tree: any) => tree.TreeIdQRCDocumentPath);
        setQRCodePaths(extractedPaths);

        /* table data export in excel sheet */
        const processedExcelData =
          response &&
          response.treeReportData.map((item: any, index: number) => {
            return {
              "Dead Tree Tag": item.TreeTag,
              "General Name": item.TreeBotanicalName.TreeGeneralName,
              "Botanical Name": item.TreeBotanicalName.BotanicalName,
              "Plantation Date": moment(item.PlantationDate).format("DD-MM-YYYY"),
              "Last Tracking Date": moment(item.TreeTransaction[0].TrackingDate).format("DD-MM-YYYY"),
              "Height(cm)": item.TreeTransaction[0].HeightInCm,
              "Girth(cm)": item.TreeTransaction[0]?.GirthInCm ?? 0,
              "Health Status": item.TreeTransaction[0].TreeHealthCondition.TreeHealth,
              "Project Name": item.Area.Project.ProjectName,
              "Cluster Name": item.Area.Cluster.ClusterName,
              "Village Name": item.Area.Village.VillageName,
              "Area Name": item.Area.AreaName,
              "Replant Tree Tag": item.ReplantTreeTag,
              "Farmer Name": item.Farmer.FarmerName
            };
          });

        setReportExportData(processedExcelData);
      } catch (error) {
        message.destroy();
        setReportsData([]);
        setIsModalOpen(true);
        setHandleSubmit(false);
        //message.warning("Message: " + (error?.message || ""));
      } finally {
        setFormLoader(false);
        setHandleSubmit(false);
      }
    };

    const fetchList = async () => {
      if (handleSubmit) {
        await fetchData();
      }
    };

    fetchList();
  }, [handleSubmit]);

  /* project list */
  useEffect(() => {
    const fetchlist = async () => {
      try {
        setProjectlist([]);
        const response = await orgmasterServices.getOneOrgMasterListById(TOKEN!, OrgMasterModulesAndMapping.PROJECT, String(!isActive));
        const res = response.AllRecordsList.map((item: any) => {
          return {
            ...item,
            key: item.ProjectId,
            name: item.ProjectName,
            id: item.ProjectId
          };
        });
        if (res.length > 0) {
          setProjectlist(res);
        } else {
          setProjectlist([]);
        }
      } catch (error: any) {
        setProjectlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    fetchlist();
  }, [isActive]);

  /* cluster list */
  useEffect(() => {
    const renderList = async () => {
      if (!projectId || projectId === 0) {
        return;
      }
      try {
        setClusterlist([]);
        const response = await orgmasterServices.getOneOrgMasterListByClusterId(TOKEN!, projectId, String(!isActive));

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.ClusterId,
              name: item.ClusterName,
              id: item.ClusterId
            };
          });
        if (res.length > 0) {
          setClusterlist(res);
        } else {
          setClusterlist([]);
        }
      } catch (error: any) {
        setClusterlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (projectId) {
        await renderList();
      }
    };
    fetchList();
  }, [projectId]);

  /* village list */
  useEffect(() => {
    const renderList = async () => {
      if (!clusterId || clusterId === 0) {
        return;
      }
      try {
        setVillagelist([]);
        const response = await orgmasterServices.getOneOrgMasterListByVillageId(TOKEN!, clusterId, String(!isActive));
        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.VillageId,
              name: item.VillageName,
              id: item.VillageId
            };
          });
        if (res.length > 0) {
          setVillagelist(res);
        } else {
          setVillagelist([]);
        }
      } catch (error: any) {
        setVillagelist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (clusterId) {
        await renderList();
      }
    };
    fetchList();
  }, [clusterId]);

  /* farmer list */
  useEffect(() => {
    const renderList = async () => {
      if (!villageId || villageId === 0) {
        return;
      }
      try {
        setArealist([]);
        const response = await reportService.getFarmerListByVillageId(TOKEN!, villageId, String(isActive));

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.FarmerId,
              name: item.FarmerName,
              id: item.FarmerId
            };
          });
        if (res.length > 0) {
          setFarmerlist(res);
        } else {
          setFarmerlist([]);
        }
      } catch (error: any) {
        setFarmerlist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (villageId) {
        await renderList();
      }
    };
    fetchList();
  }, [villageId]);

  /* area list */
  useEffect(() => {
    const renderList = async () => {
      if (!villageId || villageId === 0) {
        return;
      }
      try {
        setArealist([]);
        const response = await reportService.getOneOrgMasterListByAreaId(TOKEN!, villageId, String(!isActive));

        const res =
          response &&
          response.record.map((item: any) => {
            return {
              ...item,
              key: item.AreaId,
              name: item.AreaName,
              id: item.AreaId
            };
          });
        if (res.length > 0) {
          setArealist(res);
        } else {
          setArealist([]);
        }
      } catch (error: any) {
        setArealist([]);
        message.destroy();
        message.error("Error: " + (error?.message || ""));
      }
    };

    const fetchList = async () => {
      if (villageId) {
        await renderList();
      }
    };
    fetchList();
  }, [villageId]);

  const disabledFromDate = (current: Moment | null): boolean => {
    return !!current && current > moment().endOf("day");
  };

  const disabledToDate = (current: Moment | null): boolean => {
    return !!current && !!fromDate && (current < moment(fromDate).startOf("day") || current > moment().endOf("day"));
  };

  const handleFromDateChange = (value: Moment | null) => {
    if (value) {
      setFromDate(value);

      // Update toDate if fromDate is later than current toDate
      if (value.isAfter(toDate)) {
        setToDate(moment(value).hours(23).minutes(45));
      }
    }
  };

  const handleToDateChange = (value: Moment | null) => {
    setToDate(value);
  };

  const handleChange = (pagination: TablePaginationConfig): void => {
    setPagination(pagination);
  };

  const handleGo = () => {
    if (filterSelectOption === "1") {
      if (selectDate === 7) {
        handleLastSevenDay();
      } else if (selectDate === 30) {
        handleLastThirtyDay();
      } else if (selectDate === 60) {
        handleLastSixtyDay();
      } else if (selectDate === 90) {
        handleLastNinetyDay();
      }
    }
    setHandleSubmit(true);
  };

  /* filters all function */

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const handleOptionSelection = (selectedOption: string) => {
    // Split the selectedOption to get the month and year separately
    const [monthOption, yearOption] = selectedOption.split(" - ");
    setFilterSelectOption(selectedOption);
    setSelectDate(0);
    if (selectedOption === "1") {
      setSelectDate(90);
    }

    const selectedMonthIndex = monthNames.findIndex((month) => month === monthOption);

    // Generate the fromDate and toDate strings in the format "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    const currentDate = moment();
    const currentMonth = moment().format("MMMM");

    if (currentMonth === monthOption) {
      setFromDate(
        currentDate
          .clone()
          .date(1) // Set the day to the 1st day of the month
          .month(selectedMonthIndex) // Set the month to the selected month index (0-based)
          .hour(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
      );

      setToDate(currentDate.clone().seconds(0).milliseconds(0));
    } else {
      // Handle the case when the selected month is different from the current month
      const daysInSelectedMonth = moment(selectedOption, "MMMM").daysInMonth();
      setFromDate(currentDate.clone().month(selectedMonthIndex).year(+yearOption).date(1).hour(0).minutes(0).seconds(0).milliseconds(0));

      setToDate(currentDate.clone().month(selectedMonthIndex).year(+yearOption).date(daysInSelectedMonth).hour(23).minutes(45).seconds(0).milliseconds(0));
    }
  };

  const handleLastSevenDay = () => {
    const currentDate = moment();
    const lastOneDay = moment().subtract(7, "day");

    setFromDate(lastOneDay.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(7);
  };

  const handleLastThirtyDay = () => {
    const currentDate = moment();
    const lastSevenDays = moment().subtract(30, "days");

    setFromDate(lastSevenDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(30);
  };

  const handleLastSixtyDay = () => {
    const currentDate = moment();
    const lastFifteenDays = moment().subtract(60, "days");

    setFromDate(lastFifteenDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(60);
  };

  const handleLastNinetyDay = () => {
    const currentDate = moment();
    const lastThirtyDays = moment().subtract(90, "days");

    setFromDate(lastThirtyDays.startOf("day").hours(0).minutes(0));
    setToDate(currentDate);
    setSelectDate(90);
  };

  const showFromDate = fromDate ? fromDate.format("DD-MM-YYYY") : "";
  const showToDate = toDate ? toDate.format("DD-MM-YYYY") : "";

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const exportToExcel = () => {
    const csvData = reportExportData || [];
    const worksheet = XLSX.utils.json_to_sheet(csvData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Specify the type as "array" to avoid type error
    const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `Replantation_Tree_Statistics_${showFromDate}--${showToDate}.xlsx`);
  };

  const columns: Column[] = [
    {
      align: "right",
      title: "SN",
      dataIndex: "key",
      key: "key",
      sorter: {
        compare: (a, b) => a.key - b.key
      },
      render: (text, record, index) => <div>{record.key + 1}</div>,
      width: 50
    },
    {
      align: "left",
      title: "Dead Tree Tag",
      dataIndex: "TreeTag",
      key: "TreeTag",
      sorter: {
        compare: (a, b) => a.TreeTag.localeCompare(b.TreeTag)
      },
      // render: (text) => <div>{text}</div>,
      render: (text, record) => <Link to={`/timeline/${record?.TreeId}`}>{text}</Link>,
      width: "9%"
    },
    {
      align: "left",
      title: "General Name",
      dataIndex: "TreeGeneralName",
      key: "TreeGeneralName",
      sorter: {
        compare: (a, b) => a.TreeGeneralName.localeCompare(b.TreeGeneralName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Botanical Name",
      dataIndex: "BotanicalName",
      key: "BotanicalName",
      sorter: {
        compare: (a, b) => a.BotanicalName.localeCompare(b.BotanicalName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "right",
      title: "Plantation Date",
      dataIndex: "PlantationDate",
      key: "PlantationDate",
      sorter: {
        compare: (a, b) => a.PlantationDate.localeCompare(b.PlantationDate)
      },
      render: (text) => <div>{moment(text).format("DD-MM-YYYY")}</div>
    },
    {
      align: "right",
      title: "Last Tracking Date",
      dataIndex: "TrackingDate",
      key: "TrackingDate",
      sorter: {
        compare: (a, b) => a.TrackingDate.localeCompare(b.TrackingDate)
      },
      render: (text) => <div>{moment(text).format("DD-MM-YYYY")}</div>
    },
    {
      align: "right",
      title: "Height(cm)",
      dataIndex: "HeightInCm",
      key: "HeightInCm",
      sorter: {
        compare: (a, b) => a.HeightInCm.localeCompare(b.HeightInCm)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "right",
      title: "Girth(cm)",
      dataIndex: "GirthInCm",
      key: "GirthInCm",
      sorter: {
        compare: (a, b) => {
          // Convert the values to numbers before comparing
          const girthA = parseFloat(a.GirthInCm);
          const girthB = parseFloat(b.GirthInCm);
          return girthA - girthB;
        }
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Project",
      dataIndex: "ProjectName",
      key: "ProjectName",
      sorter: {
        compare: (a, b) => a.ProjectName.localeCompare(b.ProjectName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Cluster",
      dataIndex: "ClusterName",
      key: "ClusterName",
      sorter: {
        compare: (a, b) => a.ClusterName.localeCompare(b.ClusterName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Village",
      dataIndex: "VillageName",
      key: "VillageName",
      sorter: {
        compare: (a, b) => a.VillageName.localeCompare(b.VillageName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Area",
      dataIndex: "AreaName",
      key: "AreaName",
      sorter: {
        compare: (a, b) => a.AreaName.localeCompare(b.AreaName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Farmer",
      dataIndex: "FarmerName",
      key: "FarmerName",
      sorter: {
        compare: (a, b) => a.FarmerName.localeCompare(b.FarmerName)
      },
      render: (text) => <div>{text}</div>
    },
    {
      align: "left",
      title: "Replant Tree Tag",
      dataIndex: "ReplantTreeTag",
      key: "ReplantTreeTag",
      sorter: {
        compare: (a, b) => a.ReplantTreeTag.localeCompare(b.ReplantTreeTag)
      },
      render: (text, record) => <Link to={`/timeline/${record?.ReplantTreeId}`}>{text}</Link>
    }
  ];

  const handleChangeProject = (value: number) => {
    setReportsData([]);
    setProjectId(value);
    setClusterlist([]);
    setVillagelist([]);
    setFarmerlist([]);
    setFarmerId(null);
    setArealist([]);
    setClusterId(null);
    setVillageId(null);
    setAreaId([]);
  };
  const handleChangeCluster = (value: number) => {
    setReportsData([]);
    setClusterId(value);
    setVillagelist([]);
    setFarmerlist([]);
    setFarmerId(null);
    setArealist([]);
    setVillageId(null);
    setAreaId([]);
  };
  const handleChangeVillage = (value: number) => {
    setReportsData([]);
    setVillageId(value);
    setFarmerlist([]);
    setFarmerId(null);
    setArealist([]);
    setAreaId([]);
  };
  const handleChangeArea = (value: number[]) => {
    setReportsData([]);
    setAreaId(value);
  };
  const handleChangeFarmer = (value: number) => {
    setReportsData([]);
    setFarmerId(value);
  };
  const handleChangeHealthCondition = (value: any) => {
    setReportsData([]);
    setHealthConditionId(value);
  };

  const renderSelect = (key: string, value: any, handleChange: any, options: any) => {
    const selectAllOption = { id: "all", name: "Select All" };
    const unselectAllOption = { id: "none", name: "Unselect All" };

    // Function to handle "Select All" click
    const handleSelectAll = () => {
      const allOptionIds = options.map((option: any) => option.id);
      handleChange([...allOptionIds]);
    };

    // Function to handle "Unselect All" click
    const handleUnselectAll = () => {
      handleChange([]);
    };

    const isSelectAllSelected = Array.isArray(value) && value.includes(selectAllOption.id);

    // If "Select All" is selected, update value to include all option IDs
    const updatedValue = isSelectAllSelected ? options.map((option: any) => option.id) : value;

    return (
      <Select
        getPopupContainer={(trigger) => trigger.parentElement}
        showSearch
        allowClear
        maxTagCount="responsive"
        dropdownStyle={{ overflowY: "auto" }}
        mode={key === "Area" || key === "Health Condition" ? "multiple" : undefined}
        optionFilterProp="children"
        disabled={options.length === 0}
        className={`${styles["input-dropdown"]}`}
        placeholder={`Select ${key}`}
        style={{ minWidth: "100%", width: 200 }}
        filterOption={(input: any, option: any) => {
          const children = option?.children as unknown;
          if (typeof children === "string") {
            return (children as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
        onChange={(newValue: any) => {
          if (key === "Area" || key === "Health Condition") {
            if (Array.isArray(newValue) && newValue.includes(selectAllOption.id)) {
              handleSelectAll();
            } else if (Array.isArray(newValue) && newValue.includes(unselectAllOption.id)) {
              handleUnselectAll();
            } else {
              handleChange(Array.isArray(newValue) ? newValue.filter((id: any) => id !== selectAllOption.id && id !== unselectAllOption.id) : []);
            }
          } else {
            handleChange(newValue);
          }
        }}
        value={key === "Area" || key === "Health Condition" ? updatedValue : value}
      >
        {/* Add the "Select All" option */}
        {key === "Area" || key === "Health Condition" ? (
          <Select.Option value={selectAllOption.id} key={selectAllOption.id}>
            {selectAllOption.name}
          </Select.Option>
        ) : null}

        {/* Add the "Unselect All" option */}
        {/* Add the "Placeholder option" option */}
        {key === "Area" || key === "Health Condition" ? (
          <Select.Option value={unselectAllOption.id} key={unselectAllOption.id}>
            {unselectAllOption.name}
          </Select.Option>
        ) : (
          <Select.Option value={[]}>{`Select ${key}`}</Select.Option>
        )}

        {/* Render other options */}
        {options?.map((option: any) => (
          <Select.Option value={option.id} key={option.id}>
            {option.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  /* handle active or inactive data*/
  const handleChangeIsInActive = (e: CheckboxChangeEvent) => {
    setIsactive(e.target.checked);
    setProjectlist([]);
    setClusterlist([]);
    setVillagelist([]);
    setFarmerId(null);
    setFarmerlist([]);
    setArealist([]);
    setProjectId(null);
    setClusterId(null);
    setVillageId(null);
    setAreaId([]);
  };

  return (
    <>
      <Card className="card--height">
        <CommonModal resetModal={handleOk} showModal={isModalOpen} modalMessage={"No data found for this selection."} title="Message" />
        <h3>Replantation Tree Statistics</h3>
        <div style={{ display: "flex" }}>
          <div style={{ fontWeight: "600", marginTop: "20px" }}>
            <Checkbox onChange={handleChangeIsInActive} checked={isActive}>
              Include In-active Projects
            </Checkbox>
          </div>
        </div>
        <div className={styles["filter-container-filter1"]}>
          <div className={styles["filter-container-filter1-container"]}>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Project", projectId, handleChangeProject, projectlist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Cluster", clusterId, handleChangeCluster, clusterlist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Village", villageId, handleChangeVillage, villagelist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Area", areaId, handleChangeArea, arealist)}</div>
            <div className={styles["filter-container-filter1-select"]}>{renderSelect("Farmer", farmerId, handleChangeFarmer, farmerlist)}</div>
          </div>
        </div>
        <div className={styles["filter-container-filter2"]}>
          <div className={styles["filter-dropdown-container"]} style={{ width: navCollapsed ? "160px" : "200px" }}>
            <Dropdown onSelectOption={handleOptionSelection} />
          </div>
          {filterSelectOption === "1" ? (
            <div className={styles["filter-Lastdays"]}>
              <div
                className={`${styles["filter-dashboard-option"]} ${selectDate === 7 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 7 ? "#fff" : "",
                  opacity: 1
                }}
                // className={styles["filter-dashboard-option"]}
                // style={{
                //   backgroundColor: selectDate === 7 ? "#1860ab" : "",
                //   borderColor: selectDate === 7 ? "#1860ab" : "",
                //   color: selectDate === 7 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                onClick={handleLastSevenDay}
              >
                7
              </div>
              <div
                className={`${styles["filter-dashboard-option"]} ${selectDate === 30 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 30 ? "#fff" : "",
                  opacity: 1
                }}
                // style={{
                //   backgroundColor: selectDate === 30 ? "#1860ab" : "",
                //   borderColor: selectDate === 30 ? "#1860ab" : "",
                //   color: selectDate === 30 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                // className={styles["filter-dashboard-option"]}
                onClick={handleLastThirtyDay}
              >
                30
              </div>
              <div
                className={`${styles["filter-dashboard-option"]} ${selectDate === 60 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 60 ? "#fff" : "",
                  opacity: 1
                }}
                // style={{
                //   backgroundColor: selectDate === 60 ? "#1860ab" : "",
                //   borderColor: selectDate === 60 ? "#1860ab" : "",
                //   color: selectDate === 60 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                // className={styles["filter-dashboard-option"]}
                onClick={handleLastSixtyDay}
              >
                60
              </div>
              <div
                // style={{
                //   backgroundColor: selectDate === 90 ? "#1860ab" : "",
                //   borderColor: selectDate === 90 ? "#1860ab" : "",
                //   color: selectDate === 90 ? "#fff" : "",
                //   cursor: "pointer",
                //   opacity: 1
                // }}
                // className={styles["filter-dashboard-option"]}
                className={`${styles["filter-dashboard-option"]} ${selectDate === 90 ? "gx-bg-primary" : ""} `}
                style={{
                  cursor: "pointer",
                  color: selectDate === 90 ? "#fff" : "",
                  opacity: 1
                }}
                onClick={handleLastNinetyDay}
              >
                90
              </div>
            </div>
          ) : filterSelectOption === "2" ? (
            <div className={styles["filter-customDate"]}>
              <DatePicker style={{ width: "150px" }} format="DD-MM-YYYY" className={styles["datepicker"]} allowClear={false} disabledDate={disabledFromDate} value={fromDate} placeholder="Select Start Date" onChange={handleFromDateChange} />
              <DatePicker style={{ width: "150px" }} format="DD-MM-YYYY" className={styles["datepicker"]} allowClear={false} disabledDate={disabledToDate} value={toDate} placeholder="Select End Date" onChange={handleToDateChange} />
            </div>
          ) : (
            ""
          )}

          <div className={styles["filter-showDate-container"]}>
            <div className={styles["filter-showDate-container-1"]}>
              <span>From Date :</span>
              <span>{showFromDate}</span>
            </div>
            <div className={styles["filter-showDate-container-1"]}>
              <span>To Date :</span>
              <span>{showToDate}</span>
            </div>
          </div>

          <div className={styles["filterClick"]}>
            <Button style={{ marginLeft: "12px", display: "block", margin: "0 auto" }} type="primary" className={`${styles["filter-handleGO"]}`} onClick={handleGo}>
              Show Details
            </Button>
          </div>

          <div className={`${styles["trecords"]}`}>
            <h4>Total Records: {(reportsData && reportsData?.length) ?? 0}</h4>
          </div>
          <div className={`${styles["exportButton"]}`}>
            <Tooltip title="Download Report">
              {reportsData && reportsData?.length ? (
                <Button onClick={exportToExcel} className={styles["export-button-gradiant"]}>
                  Export
                </Button>
              ) : (
                <Button className={styles["export-button-gradiant-reset"]}>Export</Button>
              )}
            </Tooltip>
          </div>
        </div>

        {formLoader ? (
          <div className="loading loading--center">
            <Space direction="vertical" className="space--width" size="large">
              <Spin tip="Loading" size="large" />
            </Space>
          </div>
        ) : (
          <Table
            className="gx-table-responsive"
            columns={columns}
            dataSource={reportsData || undefined}
            bordered
            onChange={handleChange}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              showSizeChanger: true,
              pageSizeOptions
            }}
          ></Table>
        )}
      </Card>
    </>
  );
};

export default ReplantationReport;
